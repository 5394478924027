
.hero-section {
    text-align: center;
    height: 70vh;
    background-color: var(--first-color);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.hero-data {
    backdrop-filter: blur(9);
    background-color: rgba(222, 222, 222, .9);
    padding: 1.5rem;
    color: var(--first-color);
    font-weight: bold;
}

/* global tax */
.global-benefits {
    padding: 60px 20px;
    text-align: center;
}

.benefits-grid {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

.benefit-item {
    padding: 20px;
    border-radius: 8px;
    max-width: max-content;
    border: 3px solid var(--first-color);
    color: var(--first-color);
    width: 100%;
}

.benefit-icon {
    font-size: var(--h1-font-size);
}

.how-it-works .step {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: var(--first-color);
    color: var(--white-color);
    border-radius: 8% 92% 0% 100% / 100% 10% 90% 0% ;
    width: 100%;
    max-width: 300px;
}

/* how it work --------------------------------------- */
.how-it-works {
    background-color: var(--first-color-lighten);
}

.how-it-works .section-title {
    text-align: center;
}

.steps-number {
    font-size: var(--big-font-size);
}

.how-it-works {
    padding: 70px 20px;
}

.steps {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

.featured-destinations, .call-to-action {
    padding: 60px 20px;
    text-align: center;
}

.destinations-grid {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.destination-card, .step {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: var(--first-color);
    font-size: var(--normal-font-size);
}


.cta-button {
    background-color: var(--first-color);
    color: white;
    border: none;
    outline: none;
    font-size: var(--h3-font-size);
    padding: 10px 20px;
    margin-top: 1rem;
    cursor: pointer;
}

.cta-button:hover {
    background-color: #0056b3;
}
