.madagascar-banner-container {
    width: 100%;
    height: 200px;
}

.madagascar-banner {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.madagascar-details {
    padding-top: 1rem;
    margin-bottom: 3rem;
}

.madagascar-content {
    margin-bottom: 3rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    background-color: #CFDFE1;
    padding: 1rem;
}

.madagascar-content-image {
    position: relative;
}

.madagascar-content-img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.madagascar-content-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
}

.madagascar-container:nth-child(2) {
    background-color: #105B64;
    padding-block: 2rem;
}

.madagascar-box {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-self: center;
    text-align: center;
    gap: .5rem;
}

.madagascar-box-image {
    max-width: 600px;
    overflow: hidden;
    margin-block: .8rem;
}

.madagascar-box-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 45% 0% 71% 100% / 24% 43% 32% 36%;
}

.madagascar-box-description {
    color: var(--white-color);
}

@media screen and (min-width: 768px) {
    .madagascar-banner-container {
        width: 100%;
        height: 500px;
        overflow: hidden;
    }

    .madagascar-details {
        margin-bottom: 4rem;
    }

    .madagascar-content {
        margin-bottom: 3rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .madagascar-content-image {
        max-width: 600px;
        align-self: center;
        justify-self: center;
    }
}
