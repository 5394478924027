.footer {
  background: var(--first-color-lighten);
  box-shadow: 0 2px 8px hsla(220, 68%, 12%, 0.1);
  padding-block: 3rem;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 1rem;
  column-gap: 2rem;
}

.footer-right-pragraph {
  text-align: center;
  color: var(--first-color);
}

.footer-contact-number {
  display: flex;

  text-align: center;
  color: var(--first-color);
  font-weight: var(--font-semi-bold);
}

.footer-icon {
  font-size: var(--h2-font-size);
}

@media screen and (max-width: 900px){
  .footer-container {
    order: -1;
  }
}