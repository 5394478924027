/*=============== HEADER ===============*/
.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 2px 8px hsla(220, 68%, 12%, 0.1);
    background-color: transparent;
    z-index: var(--z-fixed);
}

.header--white {
    background-color: white;
    transition: background-color 0.3s ease; /* Optional smooth transition */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Optional for shadow effect */
}

/*=============== NAV ===============*/
.nav {
    padding-inline: var(--primary-padding-inline);
    max-width: var(--primary-max-width);
    height: calc(var(--header-height));
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.nav__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white-color);
    height: 70px;
    transition: all 0.3s;
    align-self: center;
    justify-self: center;
}

.header-logo-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.nav-toggle-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    column-gap: .5rem;
    position: relative;
    z-index: 1000;
    font-size: var(--h2-font-size);
    color: var(--white-color);
    cursor: pointer;
    transition: color 0.3s ease;
}

.nav-toggle-container:nth-of-type(2) {
    justify-content: right;
}

.nav__toggle-menu, .nav__toggle-close {
    transition: opacity 0.1s, transform 0.4s;
}

.nav-toggle-container.menu-open {
    color: var(--first-color); 
}

.header--white .nav__toggle-menu {
    color: var(--first-color);
}

.header--white span {
    color: var(--first-color);
}

@media screen and (max-width: 2000px) {
    .nav__menu {
        background-color: #CFDFE1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: max-content;
        overflow: auto;
        pointer-events: none;
        opacity: 0;
        transition: top 0.4s, opacity 0.3s;
        padding-top: 6rem;
        padding-inline: var(--secondary-padding-inline);
        padding-bottom: 3rem;
    }
    .nav__menu::-webkit-scrollbar {
        width: 0.5rem;
    }
    .nav__menu::-webkit-scrollbar-thumb {
        background-color: hsl(220, 12%, 70%);
    }
}
.nav__link {
    color: var(--first-color);
    font-weight: var(--font-semi-bold);
    padding-block: 1rem;
    display: flex;
    justify-content: space-between;
    max-width: 220px;
    gap: 2rem;
    align-items: center;
    font-size: var(--h2-font-size);
    transition: background-color 0.3s;
    cursor: pointer;
}
.nav__link:hover {
    color: #0D474D;
}

.nav-link-icon {
    font-size: var(--h3-font-size);
}

/* Show menu */
.show-menu {
    opacity: 1;
    pointer-events: initial;
}

/* Show icon */
.show-icon .nav__toggle-menu {
    opacity: 0;
    transform: rotate(90deg);
}

.show-icon .nav__toggle-close {
    opacity: 1;
    transform: rotate(90deg);
}

/* BREAKPOINTS ---------------------------------------------------------------------------- */
@media screen and (min-width: 768px) {
    .nav__logo {
        
    }
}

@media screen and (min-width: 1118px) {
    
    .nav {
        height: calc(var(--header-height) + 2rem);
    }

    .nav__logo {
        
    }
    
    .nav__list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 4rem;
    }

    .right-nav__list {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
        place-items: center;
    }
    
    .nav__list .nav-list-group:nth-child(1) {
        border-right: 2px solid var(--first-color);
        padding-right: 4rem;
    }
    
}

@media screen and (min-width: 1400px) {
    .dropdown__link {
        font-size: var(--small-font-size);
    }
}