.bread-crumbs {
  padding-block: 2rem 2rem;
  display: flex;
  align-items: center;
  text-wrap: nowrap;
  font-size: 1rem;
  max-width: var(--primary-max-width);
  text-align: left;
  flex-wrap: wrap;
  line-height: 1.5rem;
}

.bread-crumbs-link {
  opacity: 0.9;
}

.link {
  color: black;
}

.bread-crumbs-link-separator {
  padding: 1rem;
}

@media screen and (max-width: 768px) {
  .bread-crumbs {
    padding-top: 1rem;
  }

  .bread-crumbs-link {
    font-size: 0.75rem;
  }
}
