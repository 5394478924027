@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

:root {
  --header-height: 4.9rem;
  /*========== Colors ==========*/
  /*Color mode HSL(hue, saturation, lightness)*/
  --first-color: #115E67;
  --first-color-lighten: #CFDFE1;
  --title-color: hsl(220, 48%, 28%);
  --text-color: #090909;
  --body-color: hsl(220, 100%, 99%);
  --black-color: #090909;
  --gray-color: gray;
  --gray-alt-dim: #E6E6E6;
  --white-color: #fff;

  /* ---- Font and typography ---- */
  /*.5rem = 8px | 1rem = 16px ...*/
    /*.5rem = 8px | 1rem = 16px ...*/
  --big-font-size: 2rem;
  --biggest-font-size: 3rem;
  --h1-font-size: 1.4rem;
  --h2-font-size: 1.1rem;
  --h3-font-size: 1rem;
  --normal-font-size: .938rem;
  --small-font-size: .813rem;
  --smaller-font-size: .75rem;

  /* Font weight */
  --font-medium: 500;
  --font-semi-bold: 600;
  --font-semi: 700;


  /* Font weight  */
  --icon-size: 1.50rem;

  /*  z index  */
  --z-tooltip: 10;
  --z-fixed: 100;

  /* PADDING */
  --primary-padding-inline: 1.5rem;
  --secondary-padding-inline: .3rem;


  /* PRIMARY MAX WIDTH */
  --primary-max-width : 1500px;
}
@media screen and (min-width: 1118px) {
  :root {
    --big-font-size: 4rem;
    --biggest-font-size: 5rem;
    --h1-font-size: 2.3rem;
    --h2-font-size: 1.60rem;
    --h3-font-size: 1.10rem;
    --normal-font-size: 1.2em;
    --small-font-size: 1rem;
    --smaller-font-size: .813rem;
    --icon-size: 1.5rem;
    --primary-padding-inline: 2rem;
  }
}

/* --------------------BASE -------------------- */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  color: var(--text-color);
  font-family: "DM Sans", sans-serif;
}

h1, h2, h3 {
  font-weight: var(--font-medium);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

/* REUSABLE CSS CLASSES --------------------------------------------------------------------------------------------- */
.container {
  max-width: var(--primary-max-width);
  margin: auto;
  padding-inline: 1.3rem;
}

.grid {
  display: grid;
}

.section {
  padding-block: 2rem 0;
}

.section-title {
  font-size: var(--h1-font-size);
  color: var(--first-color);
}

.section-title-box {
  background-color: var(--first-color);
  color: var(--white-color);
  padding: 1rem;
  font-size: var(--h1-font-size);
  text-align: center;
}

.container-title {
  color: var(--white-color);
  padding: 1rem;
  font-size: var(--h1-font-size);
  text-align: center;
}

.section-subtitle {
  display: block;
  font-style: italic;
  font-size: var(--normal-font-size);
  padding-block: .5rem .5rem;
  color: var(--first-color);
}

.title_small-one {
  font-size: var(--smaller-font-size);
  color: var(--first-color);
  margin-bottom: 1.5rem;
}

.title_small-two {
  font-size: var(--smaller-font-size);
  margin-bottom: 1.5rem;
}

.main {
  margin-top: var(--header-height);
}

/* ICONS TYPOGRAPHY ~~~~  */
.icon {
  font-size: var(--icon-size);
  cursor: pointer;
}

.primary-icon {
  width: var(--smaller-font-size);
  height: var(--smaller-font-size);
  margin-block: auto;
}

.secondary-icon {
  font-size: var(--h3-font-size);
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr;
  padding-block: 1rem;
}

.col-md-1 {
  display: grid;
  grid-template-columns: 1fr;
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1.5rem;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr;
}

/* Reverse the order for mobile devices */
.grid-container > :nth-child(1) {
  order: 2;
}

.grid-container > :nth-child(2) {
  order: 1;
}

.vector-primary {
  width: 35px;
  height: 35px;
  margin-block: auto;
}


.primary-vector {
  width: 35px;
  height: 35px;
  margin-block: auto;
  margin-right: .3rem;
}


.secondary-vector {
  width: 35px;
  height: 35px;
}

.flex {
  display: flex;
}

.flex-center {
  display: flex;
  align-items: center;
  align-content: center;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
  justify-content: space-around;
}

.flex-column {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: .2rem;
  text-align: center;
}

.flex-row {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  column-gap: .5rem;
}

.flex-start {
  display: flex;
  flex-direction: column;
  row-gap: .3rem;
  align-items: flex-start;
  margin-bottom: 1.5rem;
}

.flex-text {
  display: flex;
  flex-direction: column;
  row-gap: 4rem;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
  color: black;
  font-size: var(--h3-font-size);
  padding-block: .3rem .3rem;
}

.text-left-sub {
  text-align: left;
  color: black;
  font-size: var(--h3-font-size);
  font-weight: bold;
  padding-block: .4rem .1rem;
}

.body-pargraph {
  margin-bottom: .7rem;
}

.secondary-body-pargraph {
  color: rgba(0, 0, 0, 0.70);
  text-align: justify;
  font-size: var(--h2-font-size);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 1rem;
}

.padding-block {
  padding-block: 1.5rem;
}

.oredered-list {
  padding-left: 1.3rem;
  padding-block: .5rem;
}

.license-list {
  list-style: circle;
  padding-left: 1.3rem;
  padding-block: .5rem;
}

.middle-img {
  margin-bottom: 1.5rem;
}

.diamond-vector {
  height: var(--small-font-size);
  width: var(--small-font-size);
}

/* WRAPPERS ~~~~~~~~~~ */
.wrapper_one {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-blend-mode: overlay;
  height: 70vh;
  color: white;
  z-index: -100;
}

.wrapper-block {
  padding-block: 2rem;
}

.wrapper-two {
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0.80);
  background: rgba(205, 205, 205, 0.15);
  box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(6px);
  margin-block: 2rem 3rem;
}

@media screen and (min-width: 768px) {
  .container {
    padding-inline: 4.5rem;
  }

  .grid-container {
    grid-template-columns: 1fr 1fr;
    padding-bottom: 2rem;
  }

  .grid-container > :nth-child(1) {
    order: 1;
    grid-column: 1;
  }

  .grid-container > :nth-child(2) {
    order: 2;
    grid-column: 2;
  }

  .image-container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    align-self: center;
  }

  .text-left {
    font-size: calc(var(--h3-font-size) + .3rem);
  }

  .primary-vector {
    width: 60px;
    height: 60px;
    margin-block: auto;
    margin-right: .3rem;
  }


  .secondary-vector {
    width: 50px;
    height: 50px;
  }

}

@media screen and (min-width: 1500px) {
  .container {
    padding-inline: 3.5rem;
  }
}